import { collection, getDocs } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import database from "../../../../firebase/firebaseConfig";
import "./enquiry.css";

const Enquiry = () => {
  const [data, setData] = useState({});
  const [display, setDisplay] = useState([]);
  const navigate = useNavigate();

  async function getData() {
    const quotes = await getDocs(collection(database, "quotes"));
    setData(
      quotes.docs.map((value) => {
        const id = value.id;
        const data = value.data();
        return { docid: id, ...data };
      })
    );
    setDisplay(
      quotes.docs.map((value) => {
        const id = value.id;
        const data = value.data();
        return { docid: id, ...data };
      })
    );
  }
  useEffect(() => {
    getData();
  }, []);

  function Search() {
    const id = document.getElementById("id").value;
    if (id === "") {
      setDisplay(data);
      return;
    }
    const filtered = data.filter((item) => {
      return (
        parseInt(item.id) === parseInt(id) ||
        id.toLowerCase().trim() === item.firstName.toLowerCase() ||
        id.toLowerCase().trim() === item.lastName.toLowerCase() ||
        id.toLowerCase().trim() === item.emailAddress.toLowerCase() ||
        id === item.mobileNumber
      );
    });
    setDisplay(filtered);
  }

  function Clear() {
    setDisplay(data);
    document.getElementById("id").value = "";
  }

  function OpenEnquiry(res) {
    navigate(`/admin/enquiry/${res.id}`, { state: res });
  }

  return (
    <div className="enquiry">
      <div className="content-padding_enquiry">
        <div className="enquiry-content">
          <h1>Enquiries</h1>
          <div className="enquiry-search">
            <input name="id" id="id" />
            <button onClick={() => Search()}>Search</button>
            <button onClick={() => Clear()}>Clear</button>
          </div>
          <div className="enquiry-quotes">
            {data.length > 0 &&
              display.map((res) => (
                <div
                  className="enquiry-card"
                  key={res.id}
                  onClick={() => OpenEnquiry({ docid: res.docid, ...res })}
                >
                  <h3>ID: {res.id}</h3>
                  <p>
                    Enquiry Date:{" "}
                    {res.timestamp
                      ? new Date(res.timestamp.seconds * 1000)
                          .toISOString()
                          .slice(0, 10)
                      : "Now"}
                  </p>
                  <p>
                    {res.firstName} {res.lastName}
                  </p>
                  <p>{res.emailAddress}</p>
                  <p>
                    <a href={`tel:${res.mobileNumber}`}>{res.mobileNumber}</a>
                  </p>
                  <p>{res.message}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
