import React from "react";
import "./quote.css";
import flower from "../../assets/flower.svg";
import database from "../../firebase/firebaseConfig";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { SendWelcomeEmail } from "../../firebase/functions";
import { useState } from "react";
import { LoadingSpinner } from "../../components";

const Quote = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submited, setSubmited] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    if (
      formJson.firstName === "" ||
      formJson.lastName === "" ||
      formJson.emailAddress === "" ||
      formJson.mobileNumber === "" ||
      formJson.message === ""
    ) {
      setErrorMessage("Please check all fields are filled out");
      setIsLoading(false);
      return;
    }
    const id = await getDoc(doc(database, "quotesid", "id"));

    setDoc(doc(database, "quotesid", "id"), {
      id: parseInt(id.data().id) + 1,
    });

    addDoc(collection(database, "quotes"), {
      ...formJson,
      timestamp: serverTimestamp(),
      id: parseInt(id.data().id),
    })
      .then((res) => {
        const body = {
          to: formJson.emailAddress,
          bcc: formJson.emailAddress,
          subject: "Our Specialists will be in touch shortly",
          id: parseInt(id.data().id),
        };
        //SendWelcomeEmail(body);
        document.getElementById("quote-form").reset();
        setErrorMessage("");
        setIsLoading(false);
        setSubmited(true);
      })
      .catch((error) => {
        setErrorMessage("Couldn't Submit Form Please Try Again");
        setIsLoading(false);
      });
  }
  return (
    <div className="quote">
      <div className="content-padding_quote">
        <div className="quote-content">
          <h3>- GET A FREE QUOTE -</h3>
          <h1>Join our thousands of happy customers</h1>
          {submited ? (
            <h3>Thank you! One of our specialist will be in touch shortly</h3>
          ) : (
            <form
              method="post"
              onSubmit={handleSubmit}
              className="quote-form"
              id="quote-form"
            >
              <div className="quote-input">
                <div className="quote-name_fields">
                  <div className="quote-input_split_left">
                    <div className="quote-title_left">
                      <p>First Name*</p>
                    </div>
                    <input name="firstName" />
                  </div>
                  <div className="quote-input_split_right" required>
                    <p>Last Name*</p>
                    <input name="lastName" />
                  </div>
                </div>
                <div className="quote-title_left">
                  <p>Email Address*</p>
                </div>
                <input name="emailAddress" type="email" />
                <div className="quote-title_left">
                  <p>Mobile Number*</p>
                </div>
                <input name="mobileNumber" type="number" />
                <div className="quote-title_left">
                  <p>Message*</p>
                </div>
                <textarea name="message" color="black" />
              </div>
              <div className="quote-button ">
                <button type="submit" disabled={isLoading}>
                  {isLoading ? <LoadingSpinner /> : "Submit"}
                </button>
              </div>
              {errorMessage && <div className="error">{errorMessage}</div>}
            </form>
          )}
        </div>
        <img src={flower} className="quote-leaf quote-background_transfrom" />
      </div>
    </div>
  );
};

export default Quote;
