import React from "react";
import { JobsSingle } from "./containers";
import { AdminNavbar } from "./components";

const JobsSinglePage = () => {
  return (
    <div>
      <AdminNavbar />
      <JobsSingle />
    </div>
  );
};

export default JobsSinglePage;
