import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./jobssingle.css";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import database from "../../../../../firebase/firebaseConfig";
import { getDownloadURL } from "firebase/storage";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../../../firebase/firebaseConfig";

const JobsSingle = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { state: data } = location;

  const [isChecked, setIsChecked] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageURL, setImageURL] = useState(
    data.quoteImages ? [...data.quoteImages] : []
  );
  const [quoteDate, setQuoteDate] = useState(null);

  const [res, setRes] = useState(data);

  const storageRef = ref(storage, `${data.id}`);

  function handleDelete() {
    console.log("delete");
    deleteDoc(doc(database, "quotes", res.docid));
    navigate("/admin/jobs");
  }

  function handleSubmit(e) {
    e.preventDefault();
    const { addressline1, addressline2, addressline3 } = e.target.elements;
    updateDoc(doc(database, "quotes", res.docid), {
      address: {
        addressline1: addressline1.value,
        addressline2: addressline2.value,
        addressline3: addressline3.value,
      },
    });
    setRes((previousState) => ({
      ...previousState,
      address: {
        addressline1: addressline1.value,
        addressline2: addressline2.value,
        addressline3: addressline3.value,
      },
    }));
  }

  function handleSubmitNote(e) {
    e.preventDefault();
    const { title, note } = e.target.elements;
    console.log(note.value.length);
    if (note.value.length > 0) {
      updateDoc(
        doc(database, "quotes", res.docid),
        {
          note: arrayUnion({
            title: title.value,
            note: note.value,
            timestamp: Date.now(),
          }),
        },
        { merge: true }
      );
      setRes((previousState) => ({
        ...previousState,
        note: [
          ...(previousState.note || []),
          { title: title.value, note: note.value, timestamp: Date.now() },
        ],
      }));
    }
  }

  function handleClick() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true;
    input.onchange = async (e) => {
      const fileList = Array.from(e.target.files);
      const uploadPromises = fileList.map((file) => {
        const imageRef = ref(storageRef, file.name);
        return uploadBytes(imageRef, file);
      });
      try {
        await Promise.all(uploadPromises);
        console.log("Images uploaded successfully!");
        setImageList(fileList);
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    };
    input.click();
    console.log(input);
  }

  useEffect(() => {
    if (imageList.length > 0) {
      console.log("running");
      const generateDownloadURLs = async () => {
        const downloadURLPromises = imageList.map((file) => {
          const imageRef = ref(storageRef, file.name);
          return getDownloadURL(imageRef);
        });

        try {
          const downloadURLs = await Promise.all(downloadURLPromises);
          setImageURL((previousState) => [...previousState, ...downloadURLs]);
          updateDoc(doc(database, "quotes", res.docid), {
            quoteImages: arrayUnion(...downloadURLs),
          });
        } catch (error) {
          console.error("Error generating download URLs:", error);
        }
      };

      generateDownloadURLs();
    }
  }, [imageList]);

  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollBy({
      left: -slider.offsetWidth, // Scroll left by the width of one slide
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollBy({
      left: slider.offsetWidth, // Scroll right by the width of one slide
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  async function handleQuote() {
    const price = document.getElementById("quoteprice");
    if (price.value !== "") {
      await addDoc(collection(database, "contracts"), {
        ...res,
        quotePrice: price.value,
      });
      await deleteDoc(doc(database, "quotes", res.docid));
      navigate("/admin/jobs");
    }
  }

  return (
    <div className="jobssingle">
      <div className="content-padding_jobssingle">
        <div className="jobssingle-content">
          <div className="js-top_buttons">
            <div className="js-goback">
              <MdChevronLeft
                className="js-slider_icon"
                onClick={() => {
                  navigate("/admin/jobs");
                }}
                size={40}
              />{" "}
              <h3>ID: {res.id}</h3>
            </div>
            <div className="js-delete">
              <button onClick={handleDelete} disabled={!isChecked}>
                Delete Contract
              </button>
              <div className="js-delete_conf">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => setIsChecked((prev) => !prev)}
                />
                <p>Confirm Delete</p>
              </div>
            </div>
          </div>
          <div className="jobssingle-card" key={res.id}>
            <p>
              <b>
                Enquiry Date:{" "}
                {res.timestamp
                  ? new Date(res.timestamp.seconds * 1000)
                      .toISOString()
                      .slice(0, 10)
                  : "Now"}
              </b>
            </p>
            <p>
              {res.firstName} {res.lastName}
            </p>
            <p>{res.emailAddress}</p>
            <p>
              <a href={`tel:${res.mobileNumber}`}>{res.mobileNumber}</a>
            </p>
            <p>{res.message}</p>
          </div>
          <div className="jobssingle-address">
            <h2>Address:</h2>
            <>
              {res.address ? (
                <div>
                  <p>{res.address.addressline1}</p>
                  <p>{res.address.addressline2}</p>
                  <p>{res.address.addressline3}</p>
                </div>
              ) : (
                <div>
                  <form
                    method="post"
                    onSubmit={handleSubmit}
                    className="jobssingle-form"
                    id="jobssingle-form"
                  >
                    <input
                      type="text"
                      placeholder="Street Address"
                      name="addressline1"
                    />
                    <input
                      type="text"
                      placeholder="Town/City"
                      name="addressline2"
                    />
                    <input
                      type="text"
                      placeholder="Post Code"
                      name="addressline3"
                    />
                    <button type="submit">Add Address</button>
                  </form>
                </div>
              )}
            </>
          </div>
          <div className="jobssingle-images">
            <h2>Images:</h2>

            <div className="js-slider">
              <MdChevronLeft
                className="js-slider_icon"
                onClick={slideLeft}
                size={40}
              />
              <div id="slider" className="js-slider_content">
                {imageURL.length > 0 &&
                  imageURL.map((item, idx) => (
                    <img
                      className="js-slider_content_image"
                      src={item}
                      alt="/"
                      key={idx}
                    />
                  ))}
              </div>
              <MdChevronRight
                className="js-slider_icon"
                onClick={slideRight}
                size={40}
              />
            </div>
            <div className="js-images_button">
              <button onClick={handleClick}>Add Images</button>
            </div>
          </div>
          <div className="js-notes">
            <h2>Notes:</h2>
            <div className="js-notes_top">
              {res.note &&
                res.note.map((notes, idx) => (
                  <div className="js-notes_item" key={idx}>
                    <h4>{notes.title}</h4>
                    <p>
                      {notes.timestamp
                        ? new Date(res.timestamp.seconds * 1000)
                            .toISOString()
                            .slice(0, 10)
                        : "Now"}
                    </p>
                    <p>{notes.note}</p>
                  </div>
                ))}
            </div>
            <div className="js-notes_content">
              <div className="js-notes_content-2">
                <form
                  method="post"
                  onSubmit={handleSubmitNote}
                  className="js-form"
                  id="js-form"
                >
                  <div className="js-title_left">
                    <p>Title</p>
                  </div>
                  <input name="title" />
                  <div className="js-title_left">
                    <p>Note*</p>
                  </div>
                  <textarea name="note" color="black" />
                  <div className="js-images_button">
                    <button type="submit">Add Note</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="js-quote">
            <h2>Complete Job:</h2>
            <div className="js-quote_grid">
              <div className="js-quote_price">
                <p>Price*</p>
                <input name="quoteprice" id="quoteprice" />
              </div>
              {/*<div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateTimePicker"]}>
                    <DateTimePicker
                      label="Date Time Picker"
                      value={quoteDate}
                      onChange={(newValue) => setQuoteDate(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>*/}
            </div>
            <div className="js-quote_button">
              <button type="submit" onClick={handleQuote}>
                Submit Quote
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsSingle;
