import React from "react";
import "./description.css";

const Description = () => {
  return (
    <div className="description">
      <div className="content-padding_description">
        <div className="description-content">
          <p>
            Welcome to Thornz, where nature thrives and beauty blossoms. Founded
            by Anthony Lees, Thornz is a premier landscaping company dedicated
            to crafting extraordinary outdoor spaces that enchant the senses.
          </p>
          <p>
            At Thornz, we believe that the landscape surrounding your home or
            business is an extension of your identity. Our team of passionate
            and skilled professionals, led by Anthony Lees, works tirelessly to
            create landscapes that reflect your unique style and elevate your
            outdoor experience.
          </p>
          <p>
            Thornz specializes in transforming ordinary spaces into
            extraordinary havens. Whether it's a small backyard oasis or a
            sprawling commercial landscape, our team is equipped with the
            knowledge and skills to deliver stunning results. We collaborate
            closely with our clients, taking the time to understand their
            desires and aspirations, ensuring that every project is a true
            reflection of their vision.
          </p>
          <p>
            At Thornz, we pride ourselves on our meticulous attention to detail
            and unwavering commitment to quality. We source only the finest
            materials and incorporate sustainable practices into our designs,
            promoting environmental responsibility. Our skilled craftsmen bring
            each design to life with precision and passion, leaving no stone
            unturned in creating landscapes that inspire awe.
          </p>
          <p>
            Our comprehensive range of services includes landscape design,
            installation, hardscaping, water features, and ongoing maintenance.
            We offer tailored solutions to meet your specific needs, whether you
            seek a serene retreat, a vibrant garden, or an inviting outdoor
            space for gatherings. Our team ensures that every element, from the
            placement of plants to the selection of materials, harmoniously
            blends together to create a cohesive and enchanting environment.
          </p>
          <p>
            At Thornz, client satisfaction is our driving force. We value open
            communication, transparency, and collaboration, ensuring that our
            clients are involved at every stage of the process. We strive to
            exceed expectations, delivering projects on time and within budget,
            while maintaining the highest standards of craftsmanship and
            professionalism.
          </p>
          <p>
            When you choose Thornz for your landscaping needs, you are choosing
            a trusted partner committed to bringing your outdoor dreams to life.
            Let us unlock the true potential of your space and create a
            landscape that reflects your individuality and enhances your
            lifestyle.
          </p>
          <p>
            Contact Thornz today and embark on a journey of transforming your
            outdoor space into a breathtaking masterpiece that will be cherished
            for years to come.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Description;
