import React from "react";
import "./call.css";
import leaftwo from "../../assets/leaftwo.svg";

const Call = () => {
  return (
    <div className="call">
      <div className="content-padding_call">
        <div className="call-content">
          <h1>Call us now!</h1>
          <a href="tel:+447552910370">
            <h3>07552 910 370</h3>
          </a>
        </div>
      </div>
      <div className="aboutus-wrapper">
        <div className="aboutus-split">
          <img src={leaftwo} className="call-img-leaf" />
        </div>
      </div>
    </div>
  );
};

export default Call;
