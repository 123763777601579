import React from "react";
import "./footer.css";
import logo from "../../logo.png";

const Footer = () => (
  <div className="footer section__padding">
    <div className="footer-links">
      <div className="footer-links_logo">
        <img src={logo} />
        <p>All Rights Reserved</p>
      </div>
      {/*<div className="footer-links_div">
        <h4>Links</h4>
        <p>Overons</p>
        <p>Social Media</p>
        <p>Counters</p>
        <p>Contact</p>
</div>*/}
      <div className="footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
      </div>
      <div className="footer-links_div">
        <h4>Get in touch</h4>
        <p>
          <a href="tel:+447552910370">07552 910 370</a>
        </p>
        {/* <p>customerservice@Thorns.com</p>*/}
      </div>
    </div>

    <div className="footer-copyright">
      <p>@2023 Thorns. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
