import React from "react";
import { Work, Why } from "../../containers";

const Service = () => {
  return (
    <div>
      <Work />
      <Why />
    </div>
  );
};

export default Service;
