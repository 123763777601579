import React from "react";
import "./about.css";
import picone from "../../assets/aboutusone.jpeg";
import pictwo from "../../assets/aboutustwo.jpeg";
import leaftwo from "../../assets/leaftwo.svg";
import aboutleaf from "../../assets/aboutleaf.svg";
import aboutheart from "../../assets/aboutheart.svg";
import aboutribbon from "../../assets/aboutribbon.svg";
import aboutspade from "../../assets/aboutspade.svg";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="about">
      <div className="content-padding_about">
        <div className="about-wrapper">
          <div className="about-split">
            <div className="about-split-left">
              <img src={picone} className="about-img-one" />
              <img src={pictwo} className="about-img-two" />
              <img src={leaftwo} className="about-img-leaf" />
            </div>
            <div className="about-split-right">
              <div className="about-content">
                <h3>- About Us</h3>
                <h1>Quality work done with passion and care</h1>
                <p>
                  Driven by Passion, Defined by Quality: Bringing Your Vision to
                  Life with Meticulous Craftsmanship
                </p>
                <div className="about-grid">
                  <div className="about-feature">
                    <img src={aboutleaf} className="about-img" />
                    <p>Eco Friendly</p>
                  </div>
                  <div className="about-feature">
                    <img src={aboutheart} className="about-img" />
                    <p>Done with love</p>
                  </div>
                  <div className="about-feature">
                    <img src={aboutribbon} className="about-img" />
                    <p>High Quality</p>
                  </div>
                  <div className="about-feature">
                    <img src={aboutspade} className="about-img" />
                    <p>Easy maintenance</p>
                  </div>
                </div>
                <div className="about-button">
                  <Link to="/about">
                    <button type="button">About Us</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
