import React from "react";
import "./portfolio.css";
import aboutusone from "../../assets/aboutusone.jpeg";
import portone from "../../assets/portone.jpeg";
import porttwo from "../../assets/porttwo.jpeg";
import portthree from "../../assets/portthree.jpeg";
import portfour from "../../assets/portfour.jpeg";
import portfive from "../../assets/portfive.jpeg";
import portsix from "../../assets/portsix.jpeg";
import portfolio1 from "../../assets/portfolio/portfolio1.JPG";
import portfolio2 from "../../assets/portfolio/portfolio2.JPG";
import portfolio3 from "../../assets/portfolio/portfolio3.JPG";
import portfolio4 from "../../assets/portfolio/portfolio4.JPG";
import portfolio5 from "../../assets/portfolio/portfolio5.jpeg";
import portfolio6 from "../../assets/portfolio/portfolio6.jpeg";
import portfolio7 from "../../assets/portfolio/portfolio7.jpeg";
import portfolio8 from "../../assets/portfolio/portfolio8.jpeg";
import portfolio9 from "../../assets/portfolio/portfolio9.jpeg";
import portfolio10 from "../../assets/portfolio/portfolio10.jpeg";
import portfolio11 from "../../assets/portfolio/portfolio11.jpeg";
import { Link } from "react-router-dom";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

const Portfolio = ({ button }) => {
  const imageList = [
    porttwo,
    portfolio1,
    portfour,
    portfolio11,
    portfive,
    aboutusone,
    portfolio2,
    portsix,
    portfolio3,
    portfolio4,
    portfolio5,
    portfolio6,
    portfolio7,
    portfolio9,
    portfolio10,
  ];
  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollBy({
      left: -slider.offsetWidth, // Scroll left by the width of one slide
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollBy({
      left: slider.offsetWidth, // Scroll right by the width of one slide
      behavior: "smooth", // Smooth scrolling animation
    });
  };
  return (
    <div className="portfolio">
      <div className="content-padding_portfolio">
        <div className="portfolio-content">
          <h1>Some of our past projects</h1>
        </div>
      </div>
      {/*<div className="portfolio-grid">
          <img src={aboutusone} className="portfolio-grid_image" />
          <img src={portone} className="portfolio-grid_image" />
          <img src={portthree} className="portfolio-grid_image three" />
          <img src={porttwo} className="portfolio-grid_image" />
          <img src={portfour} className="portfolio-grid_image" />
          <img src={portfive} className="portfolio-grid_image" />
          <img src={portsix} className="portfolio-grid_image imageDisappear" />
  </div>*/}

      <div className="is-slider">
        <MdChevronLeft
          className="is-slider_icon"
          onClick={slideLeft}
          size={40}
        />
        <div id="slider" className="is-slider_content">
          {imageList.map((item, idx) => (
            <img
              className="is-slider_content_image"
              src={item}
              alt="/"
              key={idx}
            />
          ))}
        </div>
        <MdChevronRight
          className="is-slider_icon"
          onClick={slideRight}
          size={40}
        />
      </div>

      {button ? (
        <div className="portfolio-button">
          <Link to="/quote">
            <button type="button">Get In Touch</button>
          </Link>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Portfolio;
