import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import "./adminnavbar.css";
import logo from "../../../../logo.png";
import { Link } from "react-router-dom";

const AdminNavbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="an">
      <div className="content-padding_navbar">
        <div className="header-wrapper">
          <div className="an-links">
            <div className="an-links_logo">
              <img src={logo} />
            </div>
            <div className="an-links_container">
              <p>
                <Link to="/admin/enquiry">Enquiry</Link>
              </p>
              <p>
                <Link to="/admin/jobs">Jobs</Link>
              </p>
            </div>
          </div>
          <div className="an-sign">
            <button type="button">Back to Website</button>
          </div>

          <div className="an-menu">
            {toggleMenu ? (
              <RiCloseLine
                color="#000"
                size={27}
                onClick={() => setToggleMenu(false)}
              />
            ) : (
              <RiMenu3Line
                color="#000"
                size={27}
                onClick={() => setToggleMenu(true)}
              />
            )}
            {toggleMenu && (
              <div className="an-menu_container scale-up-center">
                <div className="an-menu_container-links">
                  <p>
                    <Link
                      to="/admin/enquiry"
                      onClick={() => setToggleMenu(false)}
                    >
                      Enquiry
                    </Link>
                  </p>
                  <p>
                    <Link to="/admin/jobs" onClick={() => setToggleMenu(false)}>
                      Jobs
                    </Link>
                  </p>
                </div>
                <div className="an-menu_container-links-sign">
                  <button type="button">Back to Website</button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
