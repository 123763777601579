import React from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebaseConfig";
import "./login.css";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  console.log(location.state);

  const handleLogin = async (e) => {
    e.preventDefault();
    const { email, password } = e.target.elements;

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email.value,
        password.value
      );
      // Login successful, do something with userCredential
      console.log("Logged in successfully:", userCredential);

      // Extract the 'from' state from location object
      const { from } = { from: location.state } || { from: "/" };
      navigate(from); // Navigate the user back to the required route
    } catch (error) {
      // Handle login error
      console.log("Login error:", error);
    }
  };

  return (
    <div className="login">
      <div className="content-padding">
        <div className="login-content">
          <h3>Log in</h3>
          <form onSubmit={handleLogin} className="login-form">
            <input type="email" name="email" />
            <input type="password" name="password" />
            <button type="submit">Log In</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
