import React from "react";
import {
  Header,
  Footer,
  About,
  Services,
  Portfolio,
  Why,
  Call,
} from "../../containers";
import "./home.css";

const Home = () => {
  return (
    <div className="home">
      <Header />
      <About />
      <Services />
      <Portfolio button={true} />
      <Why />
    </div>
  );
};

export default Home;
