import React from "react";
import "./imageswiper.css";
import { useEffect } from "react";
import { useState } from "react";
import { getDownloadURL } from "firebase/storage";
import { ref, uploadBytes } from "firebase/storage";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import database, { storage } from "../../../../firebase/firebaseConfig";

const ImageSwiper = ({ data }) => {
  const [imageList, setImageList] = useState([]);
  const [imageURL, setImageURL] = useState(
    data.quoteImages ? [...data.quoteImages] : []
  );

  const res = data;

  const storageRef = ref(storage, `${data.id}`);
  function handleClick() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true;
    input.onchange = async (e) => {
      const fileList = Array.from(e.target.files);
      const uploadPromises = fileList.map((file) => {
        const imageRef = ref(storageRef, file.name);
        return uploadBytes(imageRef, file);
      });
      try {
        await Promise.all(uploadPromises);
        console.log("Images uploaded successfully!");
        setImageList(fileList);
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    };
    input.click();
    console.log(input);
  }

  useEffect(() => {
    if (imageList.length > 0) {
      console.log("running");
      const generateDownloadURLs = async () => {
        const downloadURLPromises = imageList.map((file) => {
          const imageRef = ref(storageRef, file.name);
          return getDownloadURL(imageRef);
        });

        try {
          const downloadURLs = await Promise.all(downloadURLPromises);
          setImageURL((previousState) => [...previousState, ...downloadURLs]);
          updateDoc(doc(database, "quotes", res.docid), {
            quoteImages: arrayUnion(...downloadURLs),
          });
        } catch (error) {
          console.error("Error generating download URLs:", error);
        }
      };

      generateDownloadURLs();
    }
  }, [imageList]);

  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollBy({
      left: -slider.offsetWidth, // Scroll left by the width of one slide
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollBy({
      left: slider.offsetWidth, // Scroll right by the width of one slide
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  return (
    <div className="is-images">
      <h2>Images:</h2>

      <div className="is-slider">
        <MdChevronLeft
          className="is-slider_icon"
          onClick={slideLeft}
          size={40}
        />
        <div id="slider" className="is-slider_content">
          {imageURL.length > 0 &&
            imageURL.map((item, idx) => (
              <img
                className="is-slider_content_image"
                src={item}
                alt="/"
                key={idx}
              />
            ))}
        </div>
        <MdChevronRight
          className="is-slider_icon"
          onClick={slideRight}
          size={40}
        />
      </div>
      <div className="is-images_button">
        <button onClick={handleClick}>Add Images</button>
      </div>
    </div>
  );
};

export default ImageSwiper;
