import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA92O1NCmjyJZUk-o9oXyl6Pyj-AzwjdgE",
  authDomain: "thornz-dfe46.firebaseapp.com",
  projectId: "thornz-dfe46",
  storageBucket: "thornz-dfe46.appspot.com",
  messagingSenderId: "239020207174",
  appId: "1:239020207174:web:6ccb4ee7e1f4e10cb7ee47",
};

const app = initializeApp(firebaseConfig);

const database = getFirestore(app);

const storage = getStorage(app);

const auth = getAuth(app);

export { auth };
export { storage };
export default database;
