import React from "react";
import { Quote, Portfolio, Why, Call } from "../../containers";

const Quotes = () => {
  return (
    <div>
      <Quote />
      <Call />
      <Portfolio button={false} />
      <Why />
    </div>
  );
};

export default Quotes;
