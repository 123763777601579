import React from "react";
import { Aboutus, Description, Portfolio, Why } from "../../containers";

const AboutusPage = () => {
  return (
    <div>
      <Aboutus />
      <Description />
      <Portfolio button={true} />
      <Why />
    </div>
  );
};

export default AboutusPage;
