import React from "react";
import "./why.css";

const Why = () => {
  return (
    <div className="why">
      <div className="content-padding_why">
        <div className="why-content">
          <h3>WHY US</h3>
          <h1>We are experts on everything regarding landscaping</h1>
          <p>
            Trust Our Seasoned Professionals to Guide You Through Every
            Landscaping Endeavor, Where Innovation, Knowledge, and Passion
            Combine to Create Lasting Beauty and Satisfaction
          </p>

          <div className="why-grid">
            <div className="why-grid_item">
              <div className="why-text">
                <h1 className="text">40</h1>
                <h1 className="icon">+</h1>
              </div>
              <h2>Years of Experience</h2>
            </div>
            <div className="why-grid_item">
              <div className="why-text">
                <h1 className="text">10</h1>
                <h1 className="icon">+</h1>
              </div>
              <h2>Specialist team members</h2>
            </div>
            <div className="why-grid_item">
              <div className="why-text">
                <h1 className="text">50</h1>
                <h1 className="icon">+</h1>
              </div>
              <h2>Successful Projects</h2>
            </div>
            <div className="why-grid_item">
              <div className="why-text">
                <h1 className="text">100</h1>
                <h1 className="icon">%</h1>
              </div>
              <h2>Client Satisfaction</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;
