import React from "react";
import database from "../../firebase/firebaseConfig";
import { AdminNavbar } from "./components";
import { Jobs } from "./containers";

const JobPage = () => {
  return (
    <div>
      <AdminNavbar />
      <Jobs />
    </div>
  );
};

export default JobPage;
