import React from "react";
import "./header.css";
import house from "../../assets/house.jpeg";
import image from "../../assets/image4.jpg";
import flower from "../../assets/flower.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

const Header = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  console.log(fadeIn);
  return (
    <div className={`header ${fadeIn ? "fade-in" : ""}`} id="home">
      <div className="header-content content-padding_header">
        <div className="header-content_grid">
          <div className="header-content_left">
            <h3 className={`medium_transform ${fadeIn ? "fade-in" : ""}`}>
              - LANDSCAPE DESIGN
            </h3>
            <h1
              className={`gradient__text short_transform ${
                fadeIn ? "fade-in" : ""
              }`}
            >
              Let us bring life to your garden
            </h1>
            <p className={`medium_transform ${fadeIn ? "fade-in" : ""}`}>
              Discover the Pleasures of a Flourishing Garden as Our Experienced
              Team Creates and Maintains Your Beautiful Landscape
            </p>
            <div className="header-content__input">
              <Link to="/quote">
                <button
                  type="button"
                  className={`button long_transform ${fadeIn ? "fade-in" : ""}`}
                >
                  Get In Touch
                </button>
              </Link>
              <Link to="/service">
                <button
                  type="button"
                  className={`button2 long_transform ${
                    fadeIn ? "fade-in" : ""
                  }`}
                >
                  Our Services
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`header-image background_transfrom ${
          fadeIn ? "fade-in" : ""
        }`}
      >
        <img src={image} />
      </div>
      <img
        src={flower}
        className={`leaf background_transfrom ${fadeIn ? "fade-in" : ""}`}
      />
    </div>
  );
};

export default Header;
