import React from "react";
import { AdminNavbar } from "./components";
import { Enquiry } from "./containers";

const EnquiryPage = () => {
  return (
    <div>
      <AdminNavbar />
      <Enquiry />
    </div>
  );
};

export default EnquiryPage;
