import React from "react";
import "./work.css";
import lawn from "../../assets/serviceslawn.jpeg";
import hedgestrees from "../../assets/hedgestrees.jpeg";
import fountain from "../../assets/fountain.jpeg";
import rockery from "../../assets/rockery.jpeg";
import maintenance from "../../assets/maintenance.jpeg";
import driveways from "../../assets/driveways.jpeg";
import { Link } from "react-router-dom";

const Work = () => {
  return (
    <div className="work">
      <div className="content-padding_work">
        <div className="work-content">
          <h3>- OUR SERVICES -</h3>
          <h1>Take a look at what we offer</h1>
        </div>
        <div className="work-wrapper">
          <div className="work-grid">
            <div className="work-grid_item">
              <a className="work-grid_item_ref">
                <div className="work-grid_item_img">
                  <img src={lawn} />
                </div>
                <div className="work-grid_item_content">
                  <h1>Lawn & Turf</h1>
                  <p>
                    Our Lawn and Turf work encompass a comprehensive range of
                    solutions designed to revitalize and enhance the beauty of
                    your outdoor space. From meticulous lawn mowing and edging
                    to expert turf installation and maintenance, we specialize
                    in crafting vibrant, healthy, and well-manicured lawns.
                  </p>
                </div>
              </a>
            </div>
            <div className="work-grid_item">
              <a className="work-grid_item_ref">
                <div className="work-grid_item_img">
                  <img src={hedgestrees} />
                </div>
                <div className="work-grid_item_content">
                  <h1>Hedges & Trees</h1>
                  <p>
                    Our specialized work encompass professional maintenance,
                    shaping, and nurturing to ensure your hedges and trees
                    flourish. With a skilled team and a deep understanding of
                    various species, we provide meticulous pruning, trimming,
                    and shaping, enhancing their health and aesthetic appeal.
                  </p>
                </div>
              </a>
            </div>
            <div className="work-grid_item">
              <a className="work-grid_item_ref">
                <div className="work-grid_item_img">
                  <img src={fountain} />
                </div>
                <div className="work-grid_item_content">
                  <h1>Sculpture & Rockery</h1>
                  <p>
                    Our artistic work combine the mastery of sculpture and the
                    allure of rockery to add a unique and captivating touch to
                    your outdoor space. With a focus on creativity and
                    craftsmanship, we create custom sculptures and meticulously
                    arrange rocks to create stunning visual focal points.
                  </p>
                </div>
              </a>
            </div>
            <div className="work-grid_item">
              <a className="work-grid_item_ref">
                <div className="work-grid_item_img">
                  <img src={rockery} />
                </div>
                <div className="work-grid_item_content">
                  <h1>Fountains</h1>
                  <p>
                    Our fountain work bring a touch of tranquility and elegance
                    to your outdoor oasis. From the gentle flow of water to the
                    mesmerizing sight and soothing sound, our expert team
                    designs, installs, and maintains exquisite fountains that
                    create a captivating focal point in your landscape.
                  </p>
                </div>
              </a>
            </div>
            <div className="work-grid_item">
              <a className="work-grid_item_ref">
                <div className="work-grid_item_img">
                  <img src={maintenance} />
                </div>
                <div className="work-grid_item_content">
                  <h1>Maintenance</h1>
                  <p>
                    Our comprehensive maintenance work are tailored to keep your
                    outdoor space in impeccable condition throughout the year.
                    With a team of dedicated professionals and a meticulous
                    approach, we handle all aspects of landscape maintenance,
                    including lawn care, pruning, fertilization, irrigation, and
                    more.
                  </p>
                </div>
              </a>
            </div>
            <div className="work-grid_item">
              <a className="work-grid_item_ref">
                <div className="work-grid_item_img">
                  <img src={driveways} />
                </div>
                <div className="work-grid_item_content">
                  <h1>Driveways</h1>
                  <p>
                    Our professional driveway work are designed to elevate the
                    aesthetics and functionality of your property's entrance.
                    With a focus on craftsmanship and quality materials, we
                    offer tailored solutions for driveway installation, repair,
                    and maintenance.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="work-button">
          <Link to="/quote">
            <button type="button">Get In Touch</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Work;
