import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine, RiPhoneFill } from "react-icons/ri";
import "./navbar.css";
import logo from "../../logo.png";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="navbar">
      <div className="content-padding_navbar">
        <div className="header-wrapper">
          <div className="navbar-links">
            <div className="navbar-links_logo">
              <Link to="/">
                <img src={logo} />
              </Link>
            </div>
            <div className="navbar-links_container">
              <p>
                <Link to="/">Home</Link>
              </p>
              <p>
                <Link to="/service">Services</Link>
              </p>
              <p>
                <Link to="/about">About Us</Link>
              </p>
            </div>
          </div>
          <div className="navbar-sign">
            <button
              type="button"
              onClick={() => {
                navigate("/quote");
                setToggleMenu(false);
              }}
            >
              Get In Touch
            </button>
          </div>

          <div className="navbar-menu">
            <div className="phone-icon">
              <a href="tel:+447552910370">
                <RiPhoneFill color="#000" size={27} />
              </a>
            </div>
            {toggleMenu ? (
              <RiCloseLine
                color="#000"
                size={27}
                onClick={() => setToggleMenu(false)}
              />
            ) : (
              <RiMenu3Line
                color="#000"
                size={27}
                onClick={() => setToggleMenu(true)}
              />
            )}
            {toggleMenu && (
              <div className="navbar-menu_container scale-up-center">
                <div className="navbar-menu_container-links">
                  <p>
                    <Link to="/" onClick={() => setToggleMenu(false)}>
                      Home
                    </Link>
                  </p>
                  <p>
                    <Link to="/service" onClick={() => setToggleMenu(false)}>
                      Services
                    </Link>
                  </p>
                  <p>
                    <Link to="/about" onClick={() => setToggleMenu(false)}>
                      About Us
                    </Link>
                  </p>
                </div>
                <div className="navbar-menu_container-links-sign">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/quote");
                      setToggleMenu(false);
                    }}
                  >
                    Get In Touch
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
