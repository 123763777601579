import React from "react";
import { AdminNavbar } from "./components";

const AdminPage = () => {
  return (
    <div>
      <AdminNavbar />
      <div>
        <h3>Admin Console</h3>
        <div>
          <div>
            <p>Enquiry</p>
          </div>
          <div>
            <p>Jobs</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
