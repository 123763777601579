import React from "react";
import { EnquirySingle } from "./containers";
import { AdminNavbar } from "./components";

const EnquirySinglePage = () => {
  return (
    <div>
      <AdminNavbar />
      <EnquirySingle />
    </div>
  );
};

export default EnquirySinglePage;
