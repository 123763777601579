import React from "react";
import { Route, Routes } from "react-router-dom";
import { Footer } from "./containers";
import { Navbar, PrivateRoute } from "./components";
import {
  Service,
  Home,
  Quotes,
  AboutusPage,
  EnquiryPage,
  LoginPage,
  JobPage,
  EnquirySinglePage,
  JobsSinglePage,
  AdminPage,
} from "./pages";
import "./App.css";
import ScrollToTop from "./functions/ScrollToTop";

const App = () => (
  <div className="App">
    <div>
      <ScrollToTop />
      <Routes onUpdate={() => window.scrollTo(0, 0)}>
        <Route
          exact
          path="/"
          element={
            <>
              <Navbar />
              <Home />
            </>
          }
        />
        <Route
          path="/service"
          element={
            <>
              <Navbar />
              {/*<div className="gradient__bg">
                <Navbar />
          </div>*/}
              <Service />
            </>
          }
        />
        <Route
          path="/about"
          element={
            <>
              <Navbar />
              <AboutusPage />
            </>
          }
        />
        <Route
          path="/quote"
          element={
            <>
              <Navbar />
              <Quotes />
            </>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/admin"
          element={<PrivateRoute path="/admin" element={AdminPage} />}
        />
        <Route
          path="/admin/enquiry"
          element={<PrivateRoute path="/admin/enquiry" element={EnquiryPage} />}
        />
        <Route
          path="/admin/enquiry/:id"
          element={
            <PrivateRoute
              path="/admin/enquiry/:id"
              element={EnquirySinglePage}
            />
          }
        />
        <Route
          path="/admin/jobs"
          element={<PrivateRoute path="/admin/jobs" element={JobPage} />}
        />
        <Route
          path="/admin/jobs/:id"
          element={
            <PrivateRoute path="/admin/jobs/:id" element={JobsSinglePage} />
          }
        />
      </Routes>
    </div>
    <Footer />
  </div>
);

export default App;
