import React from "react";
import { Login } from "../../containers";

const LoginPage = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default LoginPage;
