import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Route, Navigate, useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebaseConfig";
import { Admin } from "../../pages";

const PrivateRoute = ({ path, element: Element }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAdmin(user !== null);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading state or component while checking the admin status
  }

  return (
    <>{isAdmin ? <Element /> : <Navigate to="/login" state={path} replace />}</>
  );
};

export default PrivateRoute;
